import axios from 'axios';

const fetchData = async (messages) => {
  const API_ENDPOINT = "https://api.openai.com/v1/chat/completions";
  const API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Assuming you're using environment variables
  const MODEL = "gpt-4o-mini"; // Define the model as a constant

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${API_KEY}`,
    },
  };

  const data = {
    messages: messages,
    model: MODEL,
    n: 1, // Typically set to 1
  };

  try {
    const response = await axios.post(API_ENDPOINT, data, config);
    console.log(response);
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error fetching response.";
  }
};

export default fetchData;
