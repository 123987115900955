import { get } from "lodash";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProfileImage from "../../assets/placeholder-profile.jpg";
import ChangePasswordForm from "./changePasswordForm";
import "./Header.scss";

import CreateUser from "../../components/users/create/UserCreate";
import { getUserTypeName, userTypes } from "../../constant/userManagement";
import { getUser, resetField, toggleMainLoader } from "../../store/actions";
import ChatComponent from "../../utils/chatComponent";
import SidePanel from "../../utils/sidePanel";
import Tooltip from "../../utils/tooltip";
import NavbarToggle from "../navBar/NavbarToggle";

const CHAT_VISIBILITY_KEY = 'moovsoon_chat_visibility';

const Header = ({
  logout,
  profileDetails,
  unreadNotificationsCount,
  getUser,
  resetField,
  toggleMainLoader,
  history,
}) => {
  const [chatVisible, setChatVisible] = useState(() => {
    const stored = localStorage.getItem(CHAT_VISIBILITY_KEY);
    return stored === null ? true : JSON.parse(stored);
  });

  const toggleChatVisibility = () => {
    const newValue = !chatVisible;
    setChatVisible(newValue);
    localStorage.setItem(CHAT_VISIBILITY_KEY, JSON.stringify(newValue));
  };

  const [showEditPanel, setShowEditPanel] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const renderProfileSection = () => {
    return (
      <div className="profile-wrap">
        {profileDetails ? (
          <React.Fragment>
            <div className="profile-wrap-buttons">
              <button
                className="btn btn-sm btn-default"
                onClick={toggleChatVisibility}>
                {}
                {chatVisible ? (
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-eye-off">
                      <path d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49" />
                      <path d="M14.084 14.158a3 3 0 0 1-4.242-4.242" />
                      <path d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143" />
                      <path d="m2 2 20 20" />
                    </svg>
                    Hide Alice
                  </span>
                ) : (
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-eye">
                      <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" />
                      <circle cx="12" cy="12" r="3" />
                    </svg>
                    Show Alice
                  </span>
                )}
              </button>
              <button
                className="btn btn-sm btn-default"
                onClick={() => setShowEditPanel(true)}>
                <i className="icon icon-edit mr-2" />
                Edit
              </button>
            </div>
            <div className="profile-image">
              <img
                src={
                  (profileDetails && profileDetails.profileImageURL) ||
                  ProfileImage
                }
                alt="Profile"
              />
            </div>
            <div>
              <strong>{profileDetails.fullName}</strong>
            </div>
            <div className="small">
              {getUserTypeName(profileDetails.userType)}
            </div>
            <div className="form-group m-0 text-sm">
              {profileDetails.emailAddress || (
                <div className="form-label p-0">-- Email address</div>
              )}
            </div>
            <div className="form-group m-0">
              {profileDetails.personalPhoneNo || (
                <div className="form-label p-0">-- Mobile number</div>
              )}
            </div>
          </React.Fragment>
        ) : null}
        {showChangePassword ? (
          <div className="company-details">
            <ChangePasswordForm
              onCancel={() => setShowChangePassword(false)}
              logout={logout}
            />
          </div>
        ) : (
          <>
            {profileDetails ? (
              <div className="company-details">
                {profileDetails.userType !== userTypes.AD ? (
                  <>
                    <h3 className="color-1 text-uppercase">Company Details</h3>
                    <div className="form-group mb-2">
                      <div className="form-value">
                        {profileDetails.companyName
                          ? profileDetails.companyName
                          : "--"}
                      </div>
                      <div className="form-label">Company Name</div>
                    </div>
                    <div className="form-group mb-2">
                      <div className="form-value">
                        {profileDetails.officePhoneNo
                          ? profileDetails.officePhoneNo
                          : "--"}
                      </div>
                      <div className="form-label">Phone Number</div>
                    </div>
                    <div className="form-group mb-2">
                      <div className="form-value">
                        {profileDetails.address1
                          ? `${profileDetails.address1}, ${profileDetails.address2}`
                          : "--"}
                      </div>
                      <div className="form-label">Address</div>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            <div className="text-center mb-3">
              <button
                type="button"
                className="btn btn-sm btn-default"
                onClick={() => setShowChangePassword(true)}>
                Change password
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const onCreate = () => {
    setShowEditPanel(false);
    const successHandler = (event) => {
      const userDetails = event.result;
      userDetails && resetField("Auth.userDetails", userDetails);
    };
    profileDetails &&
      profileDetails.userId &&
      getUser(
        { userId: profileDetails.userId, type: "SESSION" },
        successHandler
      );
  };

  const onCancel = () => {
    setShowEditPanel(false);
    toggleMainLoader(false);
  };

  return (
    <header
      className="moovsoon-page-header"
      data-notification-count={unreadNotificationsCount}>
      <NavbarToggle />

      <div className="header-container">
        <Tooltip
          message="Notifications"
          placement="left"
          component={
            <button
              type="button"
              className={`btn btn-cell btn-40 btn-light ml-3 mr-3 notification-btn ${
                unreadNotificationsCount > 0 ? "has-unread" : ""
              }`}
              onClick={() => history.push("/notifications")}>
              <i className="icon icon-bell" />
            </button>
          }
        />
        <Dropdown>
          <Dropdown.Toggle as="div">
            <div className="d-flex align-items-center pointer profile-dropdown">
              <div className="profile-btn mr-3">
                <img
                  className="profile-btn-image"
                  src={
                    profileDetails && profileDetails.profileImageURL
                      ? profileDetails.profileImageURL
                      : ProfileImage
                  }
                  alt="Profile"
                />
              </div>
              <div className="mr-3">
                {profileDetails && profileDetails.fullName}
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div>
              <div>{renderProfileSection()}</div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {chatVisible && profileDetails && (
          <ChatComponent profileDetails={profileDetails} />
        )}
        <Tooltip
          message="Logout"
          placement="left"
          component={
            <button
              type="button"
              className="btn btn-cell btn-40 btn-light mr-3 ml-3 logout-btn"
              onClick={logout}>
              <i className="icon icon-logout" />
            </button>
          }
        />
      </div>
      <SidePanel
        show={showEditPanel}
        onHide={() => setShowEditPanel(false)}
        component={CreateUser}
        componentProps={{
          initialValues: profileDetails,
          profileEdit: true,
          onCreate,
          onCancel,
        }}
      />
    </header>
  );
};

const mapStateToProps = (state) => ({
  profileDetails: get(state, "Auth.profileDetails", null),
  unreadNotificationsCount: get(
    state,
    "Auth.profileDetails.unreadNotificationsCount",
    0
  ),
});

const mapDispatchToProps = {
  getUser,
  resetField,
  toggleMainLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
